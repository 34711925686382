import React from 'react';
import './Header.css';

const Header = () => {
  return (
    <div className='Header' id='Header'>
      <a href='#About'>About</a>
      <a href='#Works'>Works</a>
      <a href='#Contact'>Contact</a>
    </div>
  );
};

export default Header;
