import React from 'react';
import './Works.css';
import { portfolioData } from '../data.js';

const Works = () => {
  return (
    <div className='Works' id='Works'>
      {portfolioData.map((item) => (
        <div className='container'>
          <div className='item'>
            <div className='top'>
              <a href={item.href} className='imgLink'>
                <img src={item.img} alt={item.title}></img>
              </a>
            </div>
            <div className='bottom'>
              <div className='bottomContainer'>
                <h2 key={item.key} className='work-title'>
                  {item.title}
                </h2>
              </div>
              <p key={item.key}>{item.desciption}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Works;
