import React from "react";
import "./Intro.css";

const Intro = () => {
  return (
    <div className="About" id="About">
      <div className="bio">
        <h1>About me..</h1>
        <h3>
          Hello! My name is Ryan and I enjoy designing things that live on the
          web. My interest in web development started when I began to explore
          motion graphics within Adobe's After Effects program. Throughout my
          experimentation, turns out I was manipulating Javascript code to
          automate motion repetivite tasks!
        </h3>
        <h3>
          Fast-forward to today and I've had the privledge of working alongside
          some great engineers to find solutions within exsiting technologies.
          My main focus these days is creating visually pleasing and functional
          user interfaces.
        </h3>
      </div>
    </div>
  );
};

export default Intro;
