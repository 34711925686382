import React from "react";
import "./Contact.css";

const Contact = () => {
  return (
    <div className="Contact" id="Contact">
      <div className="Email">
        <h2>Contact</h2>
        <h5>. . . .</h5>
        <p className="gmail">ryanrambaran.fl@gmail.com</p>
        <a href="https://www.linkedin.com/in/ryan-rambaran/" className="gmail">
          linkedin.com/in/ryan-rambaran/
        </a>
      </div>
      <div className="services">
        <h2>Services</h2>
        <li className="list">Front End Development</li>
        <li className="list">UI/UX Development</li>
        <li className="list">Graphic Design</li>
      </div>
    </div>
  );
};

export default Contact;
