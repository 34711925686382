import Header from './Header/Header';
import Intro from './Intro/Intro';
import Works from './Works/Works';
import Contact from './Contact/Contact';
import './App.css';

function App() {
  return (
    <div className='App'>
      <Header />
      <div className='sections'>
        <Intro />
        <Works />
        <Contact />
      </div>
    </div>
  );
}

export default App;
