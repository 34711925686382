import typeface from "./Assets/typedfaces2.png";
import gallery from "./Assets/gallery2.png";
import archivePDF from "./Assets/archivePDF.png";

export const portfolioData = [
  {
    id: 1,
    title: "Archive PDF",
    desciption:
      "A couture-fashion oriented blog which I had to pleasure to work on alongside other devs and designers. For this project, my main focus was UI development while occasionally performing front-end tasks as needed.",
    img: archivePDF,
    href: "https://beta.archivepdf.net/",
  },
  {
    id: 2,
    title: "Typed-face",
    desciption:
      "Is it typeface or font? If you guessed right, check out this site that allows one to select a typeface with real-time editable attributes such as size, weight, leading, and an italic font style option.",
    img: typeface,
    href: "https://ryanramb.github.io/TypedFaces/",
  },
  {
    id: 3,
    title: "Photo Gallery",
    desciption:
      "This minimalistic portfolio serves as an interactive on-click photo gallery. It gives a virtual twist on a actual gallery display with side-by-side title/description and photograph.",
    img: gallery,
    href: "https://ryanramb.github.io/Simple-Photo-Gallery/",
  },
];
